import React from "react";
import { FaWifi, FaSignal, FaCloud, FaSatelliteDish } from "react-icons/fa";
import bannerImg from "../assets/banner-img.png";

const LandingBanner = () => {
  return (
    <div className="landing-banner container-fluid d-flex align-items-center">
      <div className="row w-100">
        {/* Left Column with Text Boxes */}
        <div className="col-md-6 col-sm-12 d-flex flex-column align-items-center justify-content-end">
          {/* Heading Section */}
          <div className="banner-left-content">
            <div className="heading-section">
              <h2 className="custom-heading1">Support for NETGEAR Products</h2>
              <h2 className="custom-heading2">Get FREE Setup &amp; Support</h2>
              <div className="button-container">
                <a href="tel:(888) 788-0417">
                  <button className="banner-btn">
                    CALL NOW (888) 788-0417
                  </button>
                </a>
              </div>
            </div>

            {/* Text Boxes with Icons */}
            <div className="row d-flex justify-content-center col-md-12 text-box-div">
              <div className="col-lg-5">
                <a href="tel:(888) 788-0417">
                  <div className="text-box d-flex align-items-center justify-content-center mx-2">
                    <FaWifi className="icon me-3" />
                    <span>Router Setup</span>
                  </div>
                </a>
              </div>

              <div className="col-lg-5">
                <a href="tel:(888) 788-0417">
                  <div className="text-box d-flex align-items-center justify-content-center mx-2">
                    <FaSignal className="icon me-3" />
                    <span>Extender Setup</span>
                  </div>
                </a>
              </div>

              <div className="col-lg-5">
                <a href="tel:(888) 788-0417">
                  <div className="text-box d-flex align-items-center justify-content-center mx-2">
                    <FaCloud className="icon me-3" />
                    <span>NightHawk Setup</span>
                  </div>
                </a>
              </div>

              <div className="col-lg-5">
                <a href="tel:(888) 788-0417">
                  <div className=" text-box d-flex align-items-center justify-content-center mx-2">
                    <FaSatelliteDish className="icon me-3" />
                    <span>Orbi Setup</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column with Image */}
        <div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
          <img src={bannerImg} alt="Banner-Image" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default LandingBanner;
