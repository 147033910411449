import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Fragment, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Common/Navbar";
import Footer from "./components/Common/Footer";
import NeedHelp from "./components/Common/NeedHelp";
import HomePage from "./pages/homePage";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsAndConditions from "./pages/TermsAndConditions";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import NotFound from "./pages/NotFound";
import ScrollToTop from "./components/Common/ScrollToTop";
import { Helmet } from "react-helmet";

function App() {
  useEffect(() => {
    // Dynamically add the JivoSite script
    const script = document.createElement("script");
    script.src = "//code.jivosite.com/widget/HbsK4Odc5O";
    script.async = true;
    document.head.appendChild(script);
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>NETGEAR Router Login & Setup</title>
        <meta
          name="description"
          content="Find expert help for Netgear router setup, Nighthawk setup and ORBI setup troubleshooting, and firmware updates. Access 24/7 support and guides to fix your Netgear issues today."
        />
        <meta
          name="keywords"
          content="netgear setup, Wifi Router Setup, netgear router setup, netgear installation assistant, netgear wifi extender login, orbi setup, configure netgear wifi extender, netgear range extender login, nighthawk setup"
        />
      </Helmet>
      {/* ------------- */}
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        {/* ------------- */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* ------------- */}
        <Footer />
        <NeedHelp />
      </BrowserRouter>
      {/* ------------- */}
    </Fragment>
  );
}

export default App;
